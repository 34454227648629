<template>

    <h3>O mně</h3>
  <p>Jmenuji se Zdenka Cihlářová a pomáhám lidem a jejich psům, aby žili spokojenější život. Spolu. Aby nemuseli bojovat jeden s&nbsp;druhým, aby našli společnou cestu i&nbsp;řeč.</p>
  <p>Pracuji především technikou pozitivního posilování.</p>

    <h5>Vzdělávání</h5>
  <p>Jsem absolventkou unikátní Akademie pro&nbsp;instruktory 2018/19 psí školy Koira. Během let jsem se účastnila seminářů Alexy Capra, mnoha komunikačních lekcí Lenky Blachové a Magdy Šimečkové, 
  mnoha teoretických seminářů a webinářů Lenky Blachové a seminářů Gaby Harris, Františka Šusty nebo webináře Elišky Šuhaj. Stále se vzdělávám ať už odbornou literaturou nebo třeba sledováním MET konferencí i účastí v&nbsp;různých výzvách s&nbsp;vlastními psy.
  Zajímá mě psí komunikace a vliv fyzického zdraví na&nbsp;chování psů.</p>
  <h5>Praxe</h5>
  <p>Od&nbsp;jara 2022 pracuji jako trenér pro&nbsp;<a href="https://www.dog-point.cz/">útulek Dogpoint</a>, kam dojíždím přibližně jednou týdne a se&nbsp;psy, kteří to potřebují, pracujeme na sebekontrole, sebevědomí, klidném chování, ošetření se souhlasem 
    a dalších věcech, které by měl pes pro&nbsp;bezproblémové fungování v rodině znát. Případně které alespoň umožní ošetřovatelům lepší manipulaci s&nbsp;daným psem.</p>
    <p>Jako trenéra mě můžete potkávat v posledních letech v oblasti mezi Mladou Boleslaví, Jičínem a Nymburkem na individuálních lekcích. Od roku 2022 pak trénuji i pod hlavičkou <a href="https://zkonymburk.blogspot.com/">ZKO Nymburk</a>.</p>
  <h5>Moji psi</h5>
  <p>Se psy žiji posledních 20 let, prvního psa - cane corso Altara jsem přivedla domů v roce 2003, po&nbsp;jeho smrti v&nbsp;roce 2013 jsem poprvé adoptovala psa 
    z&nbsp;útulku a díky Adamovi začala moje honba za informacemi, co dělat a jak žít s&nbsp;dospělým, nevychovaným, vystresovaným a občas agresivním psem. 
    Dalšího útulkáče - Charlieho jsem "vyvdala" - šlo o&nbsp;nevychovance s&nbsp;nulovou sebekontrolou. V&nbsp;létě roku 2017 se s&nbsp;adopcí mladého malého teriéra Bohouška počet 
    psích členů v naší domácnosti ustálil na třech. Na podzim 2021 zemřel Adámek a do&nbsp;smečky přibyla první fenečka - hodně bojácná a citlivá Dorotka.
  Za&nbsp;těch několik let jsem tak měla možnost pracovat a hlavně žít s&nbsp;naprosto odlišnými povahami, historií psů i s&nbsp;jejich zátěží z&nbsp;minulosti. 
  A co je asi nejdůležitější, naučila jsem se, jak se s&nbsp;nimi dobře bavit a jak se z&nbsp;toho nezbláznit :)</p> 
  <p>Se&nbsp;svými psy jsem si během let vyzkoušela klasickou poslušnost, dogdancing, nosework, agility i hoopers a v&nbsp;některých z&nbsp;těchto odvětví splnila alespoň základní zkoušky. 
    Díky tomu mám představu, který sport by mohl bavit které psy a případně odkázat na&nbsp;trenéra onoho sportu v&nbsp;okolí.</p>

</template>
<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'Home',
  components: {
    
  },

  setup () {
    useMeta({ title: 'Člověk a pes spolu - trenér psů Zdenka Cihlářová' })
  },

}
</script>

<style lang="scss">
a {
  color: $secondary
}
</style>
