<template>
   <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `Člověk a pes spolu` }}</template>
  </metainfo>

<div class= "fixed-center full-width row wrap justify-evenly items-start content-stretch " style= "border-radius: 20px; height:100vh; max-height: 1200px; max-width: 1500px;">

    <!--hlavička-->
    <div class= "col-12 q-ma-xs shadow-12" style= "height: 20%; border-radius: 20px 20px 0 0; font-family: 'Allura'; ">
    <q-img src="./assets/banner.jpg" class ="header-image" alt="hlavička stránky - hlava psa" style="border-radius: 20px 20px 0 0; z-index: -1;">
      <div class="absolute-bottom-right text-subtitle2" style="height: 100%">
        <h4>Člověk a pes - spolu</h4>
      </div>
    </q-img>

      <q-btn round color="primary" icon="menu" class="float-bottom-left lt-md z-max q-mb-xl" >
        <q-menu >
          <q-list class="menu-item" style="min-width: 100px;" @click="scroll2()">
            <router-link :to= "{name: 'home'}">
              <q-item clickable v-close-popup>
                <q-item-section class="myItem">O mně</q-item-section>
              </q-item>
            </router-link>
            <router-link :to= "{name: 'treninky'}" >
              <q-item clickable v-close-popup>
                <q-item-section class="myItem">Tréninky</q-item-section>
              </q-item>
            </router-link>
            <router-link :to= "{name: 'prihlasovani' }" >
            <q-item clickable v-close-popup>
              <q-item-section class="myItem">Přihlašování na tréninky</q-item-section>
            </q-item>
            </router-link>
            <!--
            <router-link :to= "{name: 'tabory'}" >
              <q-item clickable v-close-popup>
                <q-item-section class="myItem">Tábory</q-item-section>
              </q-item>
            </router-link>
            -->
            <router-link :to= "{name: 'kontakt'}">
            <q-item clickable v-close-popup>
              <q-item-section class="myItem">Kontakt</q-item-section>
            </q-item>
            </router-link>
          </q-list>
        </q-menu>
        </q-btn>
 
    </div>

    <!--menu-->
    <div class="gt-sm col-md-2 shadow-12" style="overflow: auto;  border-radius: 0 0 0 20px; ">
      <div class= "q-mr-xs q-py-xl menu-item" style= "background-color: white;" @click="scroll()">
    
    <router-link :to= "{name: 'home'}">
      <q-item active clickable v-ripple>
        <span class="myItem">O mě</span>
      </q-item>
    </router-link>
  
    <router-link :to= "{name: 'treninky'}">
      <q-item active clickable v-ripple>
        <span class="myItem">Tréninky</span>
      </q-item>
    </router-link>

  <router-link :to= "{name: 'prihlasovani' }">
    <q-item active clickable v-ripple>
      <span class="myItem">Přihlašování na tréninky</span>
      
    </q-item>
  </router-link>
  <!--
  <router-link :to= "{name: 'tabory'}">
    <q-item active clickable v-ripple>
      <span class="myItem">Tábory</span>
    </q-item>
  </router-link>
  -->

  <router-link :to= "{name: 'kontakt'}">
    <q-item active clickable v-ripple>
      <span class="myItem">Kontakt</span>
    </q-item>
  </router-link>
    

</div>
    </div>
    
    <!--vlastní obsah stránky-->
   
    <div class="gt-sm col-12 col-md-10 q-pa-xl shadow-12" style="  background-color: white; height: 72%; align-content: center; ">
      <div style= "max-width: 1050px; font-family: 'Garamond'; font-size: larger; height: 100% ">
        <q-scroll-area
          ref="scrollAreaRef"
          :thumb-style="thumbStyle"
          :bar-style="barStyle"
          style="height: 100%; max-width: 1050px"
          content-style="padding-right: 50px"
          content-active-style="padding-right: 50px"
          >
          <router-view/>
        </q-scroll-area>
      </div>
    </div>

    <div class="lt-md col-12 col-md-10 q-pa-sm shadow-12" style="  background-color: white; height: 72%; align-content: center; ">
      <div style= "max-width: 1050px; font-family: 'Garamond'; font-size: medium; height: 100%">
        <q-scroll-area
          ref="scrollAreaRef2"
          :thumb-style="thumbStyle"
          :bar-style="barStyle"
          style="height: 100%; max-width: 1050px"
          content-style="padding-right: 20px"
          content-active-style="padding-right: 20px"
          >
          <router-view/>
        </q-scroll-area>
      </div>
    </div>


    <!--odsazení patičky-->
    <div class= "col-0 col-md-2" style="height: 5%;">
    </div>

    <!--patička-->
    <div class= "lt-md col-12 col-md-10 shadow-12 paticka">
      <div class="fit row wrap justify-between items-baseline content-center" style="font-family: 'Garamond'; font-size: x-small; height: 100%">
        <div class="col-6 q-pl-md" style="text-align: left;">Fotka v hlavičce je od <a href="https://kopcova-karolina.webnode.cz/" target="_blank"><br>Kája-foto</a></div>
        <div class="col-6 q-pr-md" style="text-align: right;">Aktualizace 13.6.2023 <br>© Zdenka Cihlářová 2024</div>
      </div>
    </div>
    <div class= "gt-sm col-12 col-md-10 shadow-12 paticka">
      <div class="fit row wrap justify-between items-center content-center" style="font-family: 'Garamond'; font-size: small; height: 100%" >
        <div class="col-6 q-pl-md" style="text-align: left;">Fotka v hlavičce je od <a href="https://kopcova-karolina.webnode.cz/" target="_blank">Kája-foto</a></div>
        <div class="col-6 q-pr-md" style="text-align: right;">Aktualizace 13.6.2023 <br>© Zdenka Cihlářová 2024</div>
      </div>
    </div>

  
  </div>
</template>

<script>
import { ref } from 'vue'
import { useMeta } from 'vue-meta'


export default {
  name: 'App',

  components: {
  },

  /*metaInfo() {
    return { 
        title: 'Člověk a pes spolu - trenér psů Zdenka Cihlářová',
        meta: [
                { name: 'description', content:'Moje vzdělání, práce s mými vlastními psy, zkušenosti s tréninkem rodinných i problematických psů'},
              ]
              meta: [
                { name: 'description', content:  'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'},
                { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
                { property: 'og:site_name', content: 'Epiloge'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
    }
  },*/


  setup () {
    const position = ref(0)
    const scrollAreaRef = ref(null)
    const scrollAreaRef2 = ref(null)

    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })

    return {
      position,
      scrollAreaRef,
      scrollAreaRef2,

      scroll () {
        scrollAreaRef.value.setScrollPosition('vertical',  position.value)
        position.value = 0
      },

      scroll2 () {
        scrollAreaRef2.value.setScrollPosition('vertical',  position.value)
        position.value = 0
      },

      thumbStyle: {
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#fbc356',
        width: '5px',
        opacity: 0.75
      },

      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#331e0d',
        width: '9px',
        opacity: 0.2
      },

    }
  }
}

</script>

<style lang="scss">
.header-image {
  height: 100%;
  z-index: -1;
}

.menu-item {
  font-family: 'Garamont';
  color: $secondary;
  text-decoration: none;

}
a {
  text-decoration: none;
  font-size: large;
  color: $secondary;
}

body {
  background-color: $accent;
  font-family: 'Garamont';
}
h5 {
  margin-bottom: 3%;
}
.myItem:nth-child(even) {
  color: $secondary
}
.paticka {
  background-color: white; 
  height: 5%; 
  border-radius: 0 0 20px 20px; 
  overflow: auto
}
.paticka a {
  font-size: x-small;
}

</style>
